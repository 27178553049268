@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf");
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
